import nunjucks from 'nunjucks';
import newSummary from './newSummary.njk';
import filters, { getExclusions } from './filters';

const env = nunjucks.configure({
	autoescape: false,
	dev: process.env.NODE_ENV === 'development',
});

for (let [key, value] of Object.entries(filters)) {
	env.addFilter(key, value);
}

if (process.env.NODE_ENV === 'development') {
	env.addGlobal('getVars', function () {
		return this.getVariables();
	});

	env.addGlobal('getContext', function () {
		return this.ctx;
	});

	env.addFilter('prettyPrint', function (string) {
		return JSON.stringify(string, null, 2);
	});
}

export default function ({ agreementData }) {
	if(agreementData){
		let clausesFile = assignClausesFile();
		return nunjucks.renderString(clausesFile, { ...agreementData, getExclusions });
	}
}

const assignClausesFile =()=>{
	let clauseFile = newSummary;

	return clauseFile;

}
