
export default {
	methods: {
		filename(routeQuery) {
			const { partner1, partner2 } = this.agreement;

			const partner1Last = (partner1 && partner1.name.last) || '';
			const partner2Last = (partner2 && partner2.name.last) || '';
			let resultName = 'Separation Agreement';
			if(routeQuery && routeQuery.download && routeQuery.download === "summary"){
				resultName = "Separation Agreement Summary"
			}
			else if(routeQuery && routeQuery.download && routeQuery.download == 'property-schedule'){
				if(partner1Last && partner2Last)
				resultName = partner1Last == partner2Last ? `${partner1Last} Property Schedule` : `${partner1Last} ${partner2Last} Property Schedule`;
				else 
				resultName = `Property Schedule`
			} else if(partner1Last && partner2Last){
				partner1Last == partner2Last ? `${partner1Last} Separation Agreement` : `${partner1Last} ${partner2Last} Separation Agreement`;
			}
			return resultName;
		},
	},
};
